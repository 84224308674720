const formatNumber = new Intl.NumberFormat().format

const stats = {
  products: 275000,
  sellers: 1000,
  buyers: 38500,
  retailerAudience: 44000
}

export const formattedStats = {
  products: formatNumber(stats.products),
  sellers: formatNumber(stats.sellers),
  buyers: formatNumber(stats.buyers),
  retailerAudience: formatNumber(stats.retailerAudience)
}

export default stats
