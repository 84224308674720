import Router from 'next/router'
import { useQuery } from '@apollo/client'
import { DateTime } from 'luxon'

import BuyerHowToGuideModal from '../BuyerHowToGuideModal'

import GET_BUYER_FLOW_MANAGER_DETAILS from './graphql/GetBuyerFlowManagerDetails.graphql'
import {
  GetBuyerFlowManagerDetails,
  GetBuyerFlowManagerDetails_currentBuyer_billingAddress as BillingAddressType,
  GetBuyerFlowManagerDetails_currentBuyer_shippingAddress as ShippingAddressType
} from './graphql/__generated__/GetBuyerFlowManagerDetails'

/**
 * This component handles all the buyer redirects as well as components to display
 * when a Buyer visits the HomePage.
 *
 */
const BuyerFlowManager = () => {
  const { data, loading } = useQuery<GetBuyerFlowManagerDetails>(GET_BUYER_FLOW_MANAGER_DETAILS)

  if (loading) {
    return null
  }

  const buyer = data?.currentBuyer

  if (!buyer) {
    return null
  }

  const {
    helpGuideCompleted = false,
    lastRecommendationsReviewAt = null,
    shippingAddress = null,
    billingAddress = null
  } = buyer

  const isSixWeeksSinceLastRecommendation = lastRecommendationsReviewAt
    ? DateTime.now().diff(DateTime.fromISO(lastRecommendationsReviewAt), 'weeks').weeks >= 6
    : true

  const isAddressIncomplete = (address: BillingAddressType | ShippingAddressType | null) =>
    !address?.name || !address?.contact || !address?.email || !address?.phone

  const buyerHasIncompleteAddress = isAddressIncomplete(shippingAddress) || isAddressIncomplete(billingAddress)

  const handleRedirects = () => {
    if (buyerHasIncompleteAddress) {
      Router.push('/bh/account/renew-address-details')
      return true
    }

    if (isSixWeeksSinceLastRecommendation) {
      Router.push('/recommended-sellers?skipLanding=true&welcomeBack=true')
      return true
    }

    return false
  }

  if (handleRedirects()) {
    return null
  }

  return <>{!helpGuideCompleted && <BuyerHowToGuideModal isOpen />}</>
}

export default BuyerFlowManager
