import Icon from 'components/Icon'
import Button from 'components/Button'
import SwiperCore, { Pagination } from 'swiper'
import React, { useEffect, useState } from 'react'
import { gaEvent, GAEvent } from 'lib/ga'
import { Swiper, SwiperSlide } from 'swiper/react'
import UPDATE_BUYER from './graphql/MarkHelpGuideAsCompleted.graphql'
import ModalDialog, { ModalDialogProps } from 'components/ModalDialog/ModalDialog'
import GET_BUYER_HOW_TO_GUIDE_STATE from './graphql/GetBuyerHowToGuideState.graphql'
import {
  MarkHelpGuideAsCompleted,
  MarkHelpGuideAsCompletedVariables
} from './graphql/__generated__/MarkHelpGuideAsCompleted'
import { GetBuyerHowToGuideState } from './graphql/__generated__/GetBuyerHowToGuideState'

import 'swiper/css'
import 'swiper/css/pagination'
import styles from './BuyerHowToGuideModal.module.css'
import { useLazyQuery } from '@apollo/client'
import { useMutation } from 'hooks/useMutation'
import { useUserSession } from 'hooks'

type BuyerHowToGuideModalProps = Omit<ModalDialogProps, 'onExit'> & {
  onComplete?: () => void
}

const BuyerHowToGuideModal = ({ onComplete, ...props }: BuyerHowToGuideModalProps) => {
  const sliderContent = [
    {
      image: '/marketing/retailer-page/buyerHowTo/illustration-1.svg',
      heading: <h3 className={styles.slideHeading}>Welcome to Fieldfolio!</h3>,
      description: (
        <p className={styles.slideDescription}>
          Buying wholesale on Fieldfolio is simple.
          <br />
          Let us show you how.
        </p>
      )
    },
    {
      image: '/marketing/retailer-page/buyerHowTo/illustration-2.svg',
      heading: (
        <h3 className={styles.slideHeading}>
          Click on a catalog
          <br />
          to view products
        </h3>
      ),
      description: (
        <p className={styles.slideDescription}>
          Find products to buy wholesale in
          <br />
          thousands of catalogs.
        </p>
      )
    },
    {
      image: '/marketing/retailer-page/buyerHowTo/illustration-3.svg',
      heading: (
        <h3 className={styles.slideHeading}>
          Request access
          <br />
          to a catalog
        </h3>
      ),
      description: (
        <p className={styles.slideDescription}>Before ordering, you’ll need to request access from the wholesaler.</p>
      )
    },
    {
      image: '/marketing/retailer-page/buyerHowTo/illustration-4.svg',
      heading: <h3 className={styles.slideHeading}>Start ordering!</h3>,
      description: (
        <p className={styles.slideDescription}>
          Once your order is submitted,
          <br />
          the wholesaler will process it
          <br />
          and invoice you separately.
        </p>
      )
    }
  ]
  const { currentAccount } = useUserSession()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
  const [getBuyerGuideState] = useLazyQuery<GetBuyerHowToGuideState>(GET_BUYER_HOW_TO_GUIDE_STATE)
  const [updateBuyer] = useMutation<MarkHelpGuideAsCompleted, MarkHelpGuideAsCompletedVariables>(UPDATE_BUYER, {
    refetchQueries: [GET_BUYER_HOW_TO_GUIDE_STATE]
  })
  const [showBuyerGuideModal, setShowBuyerGuideModal] = useState<boolean>(false)

  useEffect(() => {
    getBuyerGuideState().then(result => {
      setShowBuyerGuideModal(!result.data?.currentBuyer.helpGuideCompleted)
    })
  }, [getBuyerGuideState])

  const handleBuyerGuideComplete = async () => {
    await updateBuyer({
      variables: {
        buyerId: `${currentAccount?.buyer?.id}`,
        attributes: {
          helpGuideCompleted: true
        }
      }
    })
    setShowBuyerGuideModal(false)
    gaEvent({
      event: GAEvent.BUYER_ONBOARDING_COMPLETE,
      params: {
        buyer_id: currentAccount?.buyer?.id
      }
    })
  }

  return (
    <ModalDialog
      contentClassName={styles.modalContainer}
      title="Buyer How to Modal"
      showHeader={false}
      closeable={false}
      onExit={() => {
        handleBuyerGuideComplete()
        if (onComplete) onComplete()
      }}
      {...props}
      isOpen={props.isOpen && showBuyerGuideModal}>
      <div className={styles.modalContentContainer}>
        <div className={styles.swiperContainer}>
          <Swiper
            modules={[Pagination]}
            onSwiper={swiper => setSwiperRef(swiper)}
            slidesPerView={1}
            pagination={{
              type: 'bullets',
              clickable: true
            }}
            onSlideChange={e => setSliderActiveIndex(e.activeIndex)}>
            {sliderContent.map((slide, index: number) => (
              <SwiperSlide key={index}>
                <div className={styles.slideContainer}>
                  <img src={slide.image} className={styles.slideImage} alt="Image" />
                  {slide.heading}
                  {slide.description}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.buttonSection}>
          {sliderActiveIndex !== 0 && (
            <Button className={styles.backButtonContainer} onClick={() => swiperRef?.slidePrev()}>
              <div className={styles.buttonContainer}>
                <Icon kind="arrow-left" size={16} className={styles.backButton} />
              </div>
            </Button>
          )}
          <Button
            isBlock
            className={styles.nextButtonContainer}
            onClick={() => {
              if (sliderActiveIndex === sliderContent.length - 1) {
                handleBuyerGuideComplete()
                if (onComplete) onComplete()
              } else {
                swiperRef?.slideNext()
              }
            }}>
            <div className={styles.buttonContainer}>
              <p className={styles.nextButtonText}>
                {sliderActiveIndex === sliderContent.length - 1 ? 'Get Started' : 'Next'}
              </p>
              <Icon kind="arrow-right" size={16} color="white" />
            </div>
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default BuyerHowToGuideModal
