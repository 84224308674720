import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'

import CatalogListHorizontal from '../CatalogListHorizontal'
import { ICatalog } from 'types/Catalog'
import { useUserSession } from 'hooks'

import GET_SELLER_BUYER_CATALOG_DATA from './graphql/GetSellerBuyerCatalogData.graphql'
import GET_REP_CATALOG_DATA from './graphql/GetRepCatalogData.graphql'
import { GetSellerBuyerCatalogData } from './graphql/__generated__/GetSellerBuyerCatalogData'
import { GetRepCatalogData } from './graphql/__generated__/GetRepCatalogData'

type YourCatalogListProps = {
  truncateAt?: number
}

const YourCatalogList = ({ truncateAt = 6 }: YourCatalogListProps) => {
  const [catalogs, setCatalogData] = useState<ICatalog[]>([])

  const { isGuest, isRep } = useUserSession()
  const [getSellerBuyerCatalog] = useLazyQuery<GetSellerBuyerCatalogData>(GET_SELLER_BUYER_CATALOG_DATA)
  const [getRepCatalogData] = useLazyQuery<GetRepCatalogData>(GET_REP_CATALOG_DATA)

  useEffect(() => {
    const fetchCatalogData = async () => {
      if (!isGuest) {
        if (isRep) {
          const result = (await getRepCatalogData()).data?.currentRep.catalogs.nodes
          setCatalogData(result as unknown as ICatalog[])
        } else {
          const result = (await getSellerBuyerCatalog()).data?.currentPromotions?.homepage.yourCatalogs?.nodes
          setCatalogData(result as unknown as ICatalog[])
        }
      }
    }
    fetchCatalogData()
  }, [getRepCatalogData, getSellerBuyerCatalog, isGuest, isRep])

  const truncatedList = truncateAt ? catalogs?.slice(0, truncateAt) ?? [] : catalogs

  if (truncatedList.length === 0) return null

  return (
    <CatalogListHorizontal
      title="Your catalogs"
      moreLink="/your-catalogs"
      moreText="All your catalogs"
      catalogs={truncatedList}
    />
  )
}

export default YourCatalogList
