import React from 'react'
import Link from 'next/link'
import classNames from 'classnames/bind'
import upperFirst from 'lodash.upperfirst'

import Icon from 'components/Icon'
import styles from './CatalogListHorizontal.module.css'
import CatalogListItem from '../CatalogListItem'
import PromotionBanner from '../PromotionBanner'
import { ISuperDepartment } from 'types/Department'
import { ICatalog } from 'types/Catalog'
import { GetHomepageData_currentPromotions_slots_nodes } from '../../pages/HomePage/graphql/__generated__/GetHomepageData'

const cx = classNames.bind(styles)

type CatalogListHorizontalProps = {
  catalogs?: ICatalog[]
  promotionSlot?: GetHomepageData_currentPromotions_slots_nodes
  superDepartment?: ISuperDepartment
  title?: string
  moreLink?: string
  showMoreLink?: boolean
  moreText?: string
  className?: string
}

const CatalogListHorizontal = ({
  title,
  moreLink,
  moreText,
  showMoreLink = true,
  promotionSlot,
  superDepartment,
  catalogs,
  className
}: CatalogListHorizontalProps) => {
  const departmentName = upperFirst(superDepartment?.name)
  const departmentSlug = superDepartment?.slug

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.headerContainer}>
        <div>
          {title && (
            <Link href={moreLink || `/shop/d/${departmentSlug}`} passHref>
              <a>
                <h2 className={styles.title}>{title}</h2>
              </a>
            </Link>
          )}
        </div>

        {showMoreLink && (
          <Link href={moreLink || `/shop/d/${departmentSlug}`} passHref>
            <a className={styles.moreLink}>
              <div className={styles.showAllCatalogsContainer}>
                <span className={styles.showAllCatalogsMobile}>More</span>
                <span className={styles.showAllCatalogs}>{moreText || `All ${departmentName} catalogs`}</span>
                <Icon kind="arrow-right" size={16} color="var(--colorGreenDark2)" />
              </div>
            </a>
          </Link>
        )}
      </div>
      <div className={cx(styles.catalogsWrapper, { hasPromotionSlot: promotionSlot })}>
        {promotionSlot && <PromotionBanner promotionSlot={promotionSlot} className={styles.promoBanner} />}
        {catalogs &&
          catalogs.length > 0 &&
          catalogs.map((catalog, index) => (
            <CatalogListItem key={index} catalog={catalog} className={styles.catalogListItem} />
          ))}
      </div>
    </div>
  )
}

export default CatalogListHorizontal
