import { useQuery } from '@apollo/client'

import ButtonLink from 'components/ButtonLink'

import GET_BUYER_UNFINISHED_ORDER from './graphql/GetBuyerUnfinishedOrder.graphql'
import { GetBuyerUnfinishedOrder } from './graphql/__generated__/GetBuyerUnfinishedOrder'
import { BuyerStatusEnum } from '../../../../../../__generated__/globalTypes'

import styles from './UnfinishedOrderBanner.module.css'

const UnfinishedOrderBanner = () => {
  const { loading, data } = useQuery<GetBuyerUnfinishedOrder>(GET_BUYER_UNFINISHED_ORDER)

  const orders = data?.currentBuyer.orders.nodes ?? []
  const orderId = orders?.[0]?.id
  const displayName = orders?.[0]?.seller?.displayName ?? ''
  const logoThumbUrl = orders?.[0]?.seller?.logoThumbUrl
  const orderLineItems = orders?.[0]?.orderLineItems
  const sellerSlug = orders?.[0]?.seller?.slug
  const catalogSlug = orders?.[0]?.catalog?.slug
  const catalogName = orders?.[0]?.catalog?.name

  const isBuyerBanned = data?.currentBuyer.status === BuyerStatusEnum.BANNED

  if (loading || !data || orders.length === 0 || isBuyerBanned) return null

  return (
    <div className={styles.container}>
      <div className={styles.bannerContainer}>
        <div className={styles.sellerDetailsContent}>
          {logoThumbUrl && <img src={logoThumbUrl} alt={`Wholesale ${displayName}`} className={styles.logoImage} />}

          <div className={styles.textContent}>
            <p className={styles.completeOrder}>
              Complete your order for <span className={styles.displayName}>{displayName}</span>
            </p>
            <p className={styles.subtext}>
              Order #{orderId} &mdash; {catalogName}
            </p>
          </div>
        </div>

        <ButtonLink
          kind="primary"
          href={orderLineItems.length > 0 ? `/bh/checkout/order/${orderId}/cart` : `/${sellerSlug}/${catalogSlug}`}
          icon="arrow-right"
          iconPosition="right"
          label={orderLineItems.length > 0 ? 'Review Order' : 'Continue Order'}
        />
      </div>
    </div>
  )
}

export default UnfinishedOrderBanner
