import classnames from 'classnames/bind'
import ButtonLink from 'components/ButtonLink'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import { formattedStats } from 'constants/marketing-stats'

import styles from './MarketingPanels.module.css'
const cx = classnames.bind(styles)

const MarketingPanel = ({
  title,
  text,
  buttonLinkKind = 'link',
  ctaLabel,
  ctaHref,
  ctaNewTab = false,
  imageSrc,
  imageAlt,
  isHero = false,
  kind = 'default'
}: {
  title: string
  text: string
  buttonLinkKind?: 'link' | 'dark'
  ctaLabel: string
  ctaHref: string
  ctaNewTab?: boolean
  imageSrc: string
  imageAlt?: string
  isHero?: boolean
  kind?: string
}) => (
  <section className={cx(styles.section, [kind])}>
    <Container>
      <div className={styles.innerContainer}>
        <div className={styles.info}>
          {isHero ? (
            <h1 className={cx(styles.title, [kind])}>{title}</h1>
          ) : (
            <h2 className={cx(styles.title, [kind])}>{title}</h2>
          )}

          <div className={cx(styles.text, [kind])}>{text}</div>
          <ButtonLink
            kind={buttonLinkKind}
            href={ctaHref}
            target={ctaNewTab ? '_blank' : ''}
            className={cx(styles.button, [kind])}
            label={ctaLabel}
          />
        </div>
        <img className={styles.image} src={imageSrc} alt={imageAlt || ctaLabel} />
      </div>
    </Container>
  </section>
)

const HomepageHeroMarketingPanel = () => (
  <MarketingPanel
    isHero
    title="Australia’s No.1 wholesale marketplace"
    text={`Over ${formattedStats.products} products, all in one place at wholesale prices.`}
    ctaLabel="Signup to Buy"
    ctaHref="/signup/buyer"
    imageSrc="/marketing/marketing-panels/ceramic-products.jpg"
  />
)

const SellerMarketingPanel = () => (
  <MarketingPanel
    title="Sell on Fieldfolio"
    text={`Australia's leading online wholesale marketplace, used by over ${formattedStats.buyers} retailers.`}
    buttonLinkKind="dark"
    ctaLabel="Start Selling Wholesale Today"
    ctaHref="/wholesaler"
    ctaNewTab
    imageSrc="/marketing/marketing-panels/wooden-utensils.jpg"
    kind="secondary"
  />
)

export default MarketingPanel

export { HomepageHeroMarketingPanel, SellerMarketingPanel }
