import React, { ReactNode } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'

import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'

import styles from './MarketingSellerTestimonialsPanel.module.css'

const testimonialCarousalData: Array<{ image: string; description: string; author: ReactNode }> = [
  {
    image: 'stamp-grind-logo.png',
    description:
      '"Fieldfolio is great because there are so many different wholesalers and products available in one place. I have tried other wholesale marketplaces, but none have been as easy as Fieldfolio. It makes trying to source products and ordering simple while trying to run a busy business!"',
    author: (
      <>
        Geoff & Karen, <strong>Stamp & Grind</strong>
      </>
    )
  },
  {
    image: 'royal-tasmania-botanic-gardens-logo.png',
    description:
      '"Fieldfolio is so easy to use and a great time saver. I\'ve discovered new unique products and wholesalers without having to go hunting. I only wish more wholesalers used it!"',
    author: (
      <>
        Esther Beecroft, <strong>Royal Tasmanian Botanical Gardens</strong>
      </>
    )
  },
  {
    image: 'codu-logo.png',
    description:
      '"Thank you for the incredible platform you\'ve built for businesses like ours. We have tried multiple platforms and trade shows before, but Fieldfolio is the one platform we use now. It just makes the whole process easy. We didn’t expect we\'d get this many new customers, and it’s still early days!"',
    author: (
      <>
        Ilya, <strong>CODU</strong>
      </>
    )
  }
]

const MarketingSellerTestimonialsPanel = () => {
  return (
    <section className={styles.panelSection}>
      <Swiper
        modules={[Autoplay]}
        autoplay={{
          delay: 8000,
          disableOnInteraction: true
        }}
        slidesPerView={1}
        speed={600}
        loop={false}
        loopedSlides={testimonialCarousalData.length}
        freeMode>
        {testimonialCarousalData.map((slideData, index) => (
          <SwiperSlide key={index}>
            <Container>
              <div className={styles.testimonialContainer}>
                <img
                  className={styles.testimonialImage}
                  src={`/marketing/testimonial-logos/${slideData.image}`}
                  alt="Testimonial Image"
                />
                <div className={styles.testimonialContent}>
                  <p className={styles.sectionTitle}>{slideData.description}</p>
                  <p className={styles.sectionDescription}>{slideData.author}</p>
                </div>
              </div>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  )
}

export default MarketingSellerTestimonialsPanel
