import Icon from 'components/Icon'
import Button from 'components/Button'
import SwiperCore, { Pagination } from 'swiper'
import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { gaEvent, GAEvent } from 'lib/ga'
import UPDATE_REP from './graphql/MarkRepHelpGuideAsCompleted.graphql'
import ModalDialog, { ModalDialogProps } from 'components/ModalDialog/ModalDialog'
import GET_REP_HOW_TO_GUIDE_STATE from './graphql/GetRepHowToGuideState.graphql'
import {
  MarkRepHelpGuideAsCompleted,
  MarkRepHelpGuideAsCompletedVariables
} from './graphql/__generated__/MarkRepHelpGuideAsCompleted'
import { GetRepHowToGuideState } from './graphql/__generated__/GetRepHowToGuideState'
import { RepKindEnum } from '../../../../../__generated__/globalTypes'

import 'swiper/css'
import styles from './RepHowToGuideModal.module.css'
import { useLazyQuery } from '@apollo/client'
import { useMutation } from 'hooks/useMutation'
import { useUserSession } from 'hooks'

type RepHowToGuideModalProps = Omit<ModalDialogProps, 'onExit'> & {
  onComplete?: () => void
}

SwiperCore.use([Pagination])
const RepHowToGuideModal = ({ onComplete, ...props }: RepHowToGuideModalProps) => {
  const sliderContent = [
    {
      image: '/marketing/rep-page/repHowTo/illustration-1.svg',
      heading: <h3 className={styles.slideHeading}>Great work!</h3>,
      description: (
        <p className={styles.slideDescription}>
          Now that you’ve got a Sales Rep profile, growing your wholesale agency business on Fieldfolio is simple.
        </p>
      )
    },
    {
      image: '/marketing/rep-page/repHowTo/illustration-2.svg',
      heading: <h3 className={styles.slideHeading}>Browse catalogs</h3>,
      description: (
        <p className={styles.slideDescription}>
          View a catalog to see whether you’d like to sell from it as a Sales Rep
        </p>
      )
    },
    {
      image: '/marketing/rep-page/repHowTo/illustration-3.svg',
      heading: <h3 className={styles.slideHeading}>Request to sell from a catalog</h3>,
      description: (
        <p className={styles.slideDescription}>
          Request sales access from the wholesaler using the request button and independently agree agency terms.
        </p>
      )
    },
    {
      image: '/marketing/rep-page/repHowTo/illustration-4.svg',
      heading: <h3 className={styles.slideHeading}>Start selling!</h3>,
      description: (
        <p className={styles.slideDescription}>
          If your request is approved, you’ll receive the usual invitation to sell from the Fieldfolio for iPad app!
        </p>
      )
    }
  ]
  const { currentAccount } = useUserSession()
  const [swiperRef, setSwiperRef] = useState<SwiperCore>()
  const [sliderActiveIndex, setSliderActiveIndex] = useState(0)
  const [getRepGuideState] = useLazyQuery<GetRepHowToGuideState>(GET_REP_HOW_TO_GUIDE_STATE)
  const [updateRep] = useMutation<MarkRepHelpGuideAsCompleted, MarkRepHelpGuideAsCompletedVariables>(UPDATE_REP, {
    refetchQueries: [GET_REP_HOW_TO_GUIDE_STATE]
  })
  const [showRepGuideModal, setShowRepGuideModal] = useState<boolean>(false)

  useEffect(() => {
    getRepGuideState().then(result => {
      const rep = result.data?.currentRep
      setShowRepGuideModal(rep?.kind === RepKindEnum.INDEPENDENT && !rep?.helpGuideCompleted)
    })
  }, [getRepGuideState])

  const handleRepGuideComplete = async () => {
    await updateRep({
      variables: {
        repId: `${currentAccount?.rep?.id}`,
        attributes: {
          helpGuideCompleted: true
        }
      }
    })
    setShowRepGuideModal(false)
    gaEvent({
      event: GAEvent.REP_ONBOARDING_COMPLETE,
      params: {
        rep_id: currentAccount?.rep?.id
      }
    })
  }

  return (
    <ModalDialog
      contentClassName={styles.modalContainer}
      title="Rep How to Modal"
      showHeader={false}
      closeable={false}
      onExit={() => {
        handleRepGuideComplete()
        if (onComplete) onComplete()
      }}
      {...props}
      isOpen={props.isOpen && showRepGuideModal}>
      <div className={styles.modalContentContainer}>
        <div className={styles.swiperContainer}>
          <Swiper
            onSwiper={swiper => setSwiperRef(swiper)}
            slidesPerView={1}
            pagination={{
              type: 'bullets',
              clickable: true
            }}
            onSlideChange={e => setSliderActiveIndex(e.activeIndex)}>
            {sliderContent.map((slide, index: number) => (
              <SwiperSlide key={index}>
                <div className={styles.slideContainer}>
                  <img src={slide.image} className={styles.slideImage} alt="Image" />
                  {slide.heading}
                  {slide.description}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className={styles.buttonSection}>
          {sliderActiveIndex !== 0 && (
            <Button className={styles.backButtonContainer} onClick={() => swiperRef?.slidePrev()}>
              <div className={styles.buttonContainer}>
                <Icon kind="arrow-left" size={16} className={styles.backButton} />
              </div>
            </Button>
          )}
          <Button
            isBlock
            className={styles.nextButtonContainer}
            onClick={() => {
              if (sliderActiveIndex === sliderContent.length - 1) {
                handleRepGuideComplete()
                if (onComplete) onComplete()
              } else {
                swiperRef?.slideNext()
              }
            }}>
            <div className={styles.buttonContainer}>
              <p className={styles.nextButtonText}>
                {sliderActiveIndex === sliderContent.length - 1 ? 'Get Started' : 'Next'}
              </p>
              <Icon kind="arrow-right" size={16} color="white" />
            </div>
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default RepHowToGuideModal
