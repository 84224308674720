import { useQuery } from '@apollo/client'
import classnames from 'classnames'

import { useUserSession } from 'hooks'

import SellerGrid from '../SellerGrid'
import ThinkingView from '../ThinkingView'
// import BuyerRequestRecommendedSellersButton from '../BuyerRequestRecommendedSellersButton'

import GET_BUYER_MARKETPLACE_RECOMMENDATIONS_DATA from './graphql/GetBuyerMarketplaceRecommendationsData.graphql'
import {
  GetBuyerMarketplaceRecommendationsData,
  GetBuyerMarketplaceRecommendationsDataVariables
} from './graphql/__generated__/GetBuyerMarketplaceRecommendationsData'
import GET_REP_MARKETPLACE_RECOMMENDATIONS_DATA from './graphql/GetRepMarketplaceRecommendationsData.graphql'
import {
  GetRepMarketplaceRecommendationsData,
  GetRepMarketplaceRecommendationsDataVariables
} from './graphql/__generated__/GetRepMarketplaceRecommendationsData'

import { SellersListProps } from '../BuyerRequestRecommendedSellersButton/BuyerRequestRecommendedSellersButton'
import {
  AccountableTypeEnum
  // PricingRequestSourceEnum
} from '../../../../../__generated__/globalTypes'

import styles from './MarketplaceRecommendationsPanel.module.css'

// Max size of Seller records to return, records=SIZE+1; 0 means return 1 record, 1 means return 2 records, etc.
const SIZE = 4

type MarketplaceRecommendationsPanelProps = {
  className?: string
  hideLoading?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const _MarketplaceRecommendationsPanel = ({ className, hideLoading = false }: MarketplaceRecommendationsPanelProps) => {
  const { isBuyer, isRep } = useUserSession()

  const { loading: buyerLoading, data: buyerData } = useQuery<
    GetBuyerMarketplaceRecommendationsData,
    GetBuyerMarketplaceRecommendationsDataVariables
  >(GET_BUYER_MARKETPLACE_RECOMMENDATIONS_DATA, {
    variables: {
      size: SIZE
    },
    skip: !isBuyer
  })

  const { loading: repLoading, data: repData } = useQuery<
    GetRepMarketplaceRecommendationsData,
    GetRepMarketplaceRecommendationsDataVariables
  >(GET_REP_MARKETPLACE_RECOMMENDATIONS_DATA, {
    variables: {
      size: SIZE
    },
    skip: !isRep
  })

  const sellers = buyerData?.currentBuyer.recommendedSellers ?? repData?.currentRep.recommendedSellers ?? []
  const sellersList: SellersListProps =
    sellers.map(seller => {
      return {
        sellerId: seller.id,
        catalogIds: seller.marketplaceCatalogs.nodes.map(catalog => catalog.id)
      }
    }) ?? []

  const loading = buyerLoading || repLoading

  if (loading) {
    if (hideLoading) {
      return null
    } else {
      return <ThinkingView />
    }
  }

  if (!loading && sellersList.length === 0) return null

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.headingContainer}>
        <div className={styles.aiSellers}>
          <img className={styles.aiLogo} src="/marketplace/ai-logo.svg" alt="ai" />
          <h2 className={styles.title}>Sellers for You</h2>
        </div>
        {/*<BuyerRequestRecommendedSellersButton
          sellersList={sellersList}
          label="Request Pricing for All"
          source={PricingRequestSourceEnum.RECOMMENDATION_ALL}
          kind="dark"
        />*/}
      </div>
      <SellerGrid sellers={sellers} accountableType={AccountableTypeEnum.BUYER} />
    </div>
  )
}

// TODO: remove this when we're ready to use the real component
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MarketplaceRecommendationsPanel = ({ className, hideLoading = false }: MarketplaceRecommendationsPanelProps) =>
  null

export default MarketplaceRecommendationsPanel
