import React from 'react'
import { formattedStats } from 'constants/marketing-stats'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'

import styles from './MarketingStatsPanel.module.css'

const stats = [
  {
    title: `${formattedStats.products}+`,
    subTitle: 'Wholesale Products',
    text: 'With one of the largest wholesale product ranges anywhere, nothing is too hard to find'
  },
  {
    title: `${formattedStats.sellers}+`,
    subTitle: 'Wholesalers',
    text: 'Over a thousand leading wholesale suppliers with unique brands do business on Fieldfolio every day'
  },
  {
    title: `${formattedStats.buyers}+`,
    subTitle: 'Buyers',
    text: `Over ${formattedStats.buyers} retailers and other buyers have already chosen Fieldfolio for their wholesale purchasing`
  },
  {
    title: '$165 million+',
    subTitle: 'Annually',
    text: 'Over $165 million in orders annually, and growing fast!'
  }
]

const MarketStatsPanel = () => (
  <section className={styles.section}>
    <Container className={styles.container}>
      {stats.map((stat, idx) => (
        <div key={idx} className={styles.stat}>
          <h3 className={styles.titles}>
            <div className={styles.title}>{stat.title}</div>
            <div className={styles.subtitle}>{stat.subTitle}</div>
          </h3>

          <p className={styles.text}>{stat.text}</p>
        </div>
      ))}
    </Container>
  </section>
)

export default MarketStatsPanel
